'use strict';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

const body = document.querySelector('body');

// Main navigation toggle

const mainNavToggle = document.querySelector('.main-nav-toggle');
const mainNav = document.querySelector('.main-nav');
const siteSearch = document.querySelector('.site-search');

mainNavToggle.addEventListener('click', function(){
  this.classList.toggle('is-selected');
  mainNav.classList.toggle('is-visible');
  siteSearch.classList.toggle('is-visible');
});

// Sub-nav toggle

const navItemsWithChildren = document.querySelectorAll('.menu-item-has-children');

navItemsWithChildren.forEach(navItem => {
  const currentLink = navItem.querySelector('a');
  const currentSubNav = navItem.querySelector('.sub-menu');

  currentLink.addEventListener('click', function(e){

    if(window.innerWidth > 1280) {
      return false;
      e.preventDefault;
    }

    e.target.classList.toggle('is-active');
    currentSubNav.classList.toggle('is-open');

    e.preventDefault;
  }); 
    
});

// Language switcher toggle

const lsToggle = document.querySelector('.location-switcher__toggle');
const languageSwitcher = document.querySelector('.location-switcher-list');

lsToggle.addEventListener('click', function(){
  this.classList.toggle('is-selected');
  languageSwitcher.classList.toggle('is-visible');
});

// Galleries

// Image gallery

const galleryContainers = document.querySelectorAll('.gallery-outer');

galleryContainers.forEach(function(galleryContainer){
  const gallery = galleryContainer.querySelector('.gallery'); 
  const prev = galleryContainer.querySelector('.gallery-control--prev');
  const next = galleryContainer.querySelector('.gallery-control--next');

  const gallerySlides = tns({
    container: gallery,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    mouseDrag: true,
    prevButton: prev,
    nextButton: next,
  });

  const info = gallerySlides.getInfo();
  const counter = galleryContainer.querySelector('.gallery-header .gallery-counter');
  const current = galleryContainer.querySelector('.gallery-header .gallery-counter__current');
  let total = galleryContainer.querySelector('.gallery-header .gallery-counter__total');

  if(counter) {
    total.textContent = info.slideCount;
  }

  gallerySlides.events.on('transitionEnd', function(info){
    current.textContent = gallerySlides.getInfo().displayIndex;
  });

});


// Product gallery

const productGalleryOuter = document.querySelector('.product-gallery-outer');
const productGallery = document.querySelector('.product-gallery');

if(productGallery) {
  const productGalleryPrev = productGalleryOuter.querySelector('.product-gallery-control--prev');
  const productGalleryNext = productGalleryOuter.querySelector('.product-gallery-control--next');

  const productGallerySlides = tns({
    container: productGallery,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    mouseDrag: true,
    prevButton: productGalleryPrev,
    nextButton: productGalleryNext,
  });

  const info = productGallerySlides.getInfo();
  const counter = document.querySelector('.product-gallery-outer .gallery-counter');
  const current = document.querySelector('.product-gallery-outer .gallery-counter__current');
  let total = document.querySelector('.product-gallery-outer .gallery-counter__total');

  if(counter) {
    total.textContent = info.slideCount;
  }

  productGallerySlides.events.on('transitionEnd', function(info){
    current.textContent = productGallerySlides.getInfo().displayIndex;
  });

}

// Key features interactive diagram

const keyFeatureBtns = document.querySelectorAll('.key-features__hotspot');

if(window.innerWidth > 768) {

  keyFeatureBtns.forEach(function (keyFeatureBtn) {
    keyFeatureBtn.addEventListener("click", function () {

      // Find the linked content
      const currentFeature = document.querySelector(
        '.key-features__text[data-hotspot-text="' + this.dataset.hotspotBtn + '"]'
      );

      // Remove classes
      document.querySelector(".key-features__text.is-visible").classList.remove("is-visible");
      document.querySelector(".key-features__hotspot.is-selected").classList.remove("is-selected");

      // Add classes
      currentFeature.classList.add("is-visible");
      this.classList.add("is-selected");
    });

  });

}

const keyFeaturesTextBlocks = document.querySelectorAll('.key-features__text');

if(window.innerWidth > 768) {

  let highest = 0;

  keyFeaturesTextBlocks.forEach(function (item) {
    const itemH = item.getBoundingClientRect().height;
    highest = itemH > highest ? itemH : highest;
  });

  keyFeaturesTextBlocks.forEach(function (item) {
    item.style.minHeight = Math.round(highest) + "px";
  });

}

// Manuals filtering

const manualsList = document.querySelector('.manuals-feed');
const targetSelector = '.mix';

function getSelectorFromHash() {
  var hash = window.location.hash.replace(/^#/g, "");

  var selector = hash ? "." + hash : targetSelector;

  return selector;
}

function setHash(state) {
  var selector = state.activeFilter.selector;
  var newHash = "#" + selector.replace(/^./g, "");

  if (selector === targetSelector && window.location.hash) {
    // Equivalent to filter "all", remove the hash

    history.pushState(null, document.title, window.location.pathname); // or history.replaceState()
  } else if (
    newHash !== window.location.hash &&
    selector !== targetSelector
  ) {
    // Change the hash

    history.pushState(
      null,
      document.title,
      window.location.pathname + newHash
    ); // or history.replaceState()
  }
}

if (manualsList) {
  const mixer = mixitup(manualsList, {
    animation: {
      enable: false,
      animateResizeContainer: false
    },
    load: {
      filter: getSelectorFromHash() // Ensure that the mixer's initial filter matches the URL on startup
    },
    callbacks: {
      onMixEnd: setHash // Call the setHash() method at the end of each operation
    }
  });
}

// Text image column heights

// Find heights of each column within text/image component
// If text column is higher than image column, add 'align-items: start' to parent container

const textImgItems = document.querySelectorAll('.text-img');


textImgItems.forEach(function(item) {
  const textCol = item.querySelector('.text-img__text');
  const imgCol = item.querySelector('.text-img__img');

  const textColHeight = textCol.getBoundingClientRect().height;
  const imgColHeight = imgCol.getBoundingClientRect().height;

  console.log(Math.round(textColHeight), Math.round(imgColHeight));

  if(textColHeight > imgColHeight) {
    // console.log('text column is higher');
    console.log(item);
    item.classList.add('is-aligned');
  } 
});



// Detect flexbox gap property

function checkFlexGap() {
  // create flex container with row-gap set
  var flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";

  // create two, elements inside it
  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex);

  return isSupported;
}

if (checkFlexGap()) {
  body.classList.add("flexbox-gap");
} else {
  body.classList.add("no-flexbox-gap");
}